
































import { Component, Vue } from 'vue-property-decorator';
import {
  IUnitProfile,
  IUnitProfileUpdate,
  IUnitProfileCreate,
} from '@/interfaces';
import { dispatchGetUnits, dispatchCreateUnit } from '@/store/unit/actions';

@Component
export default class CreateUnit extends Vue {
  public categories = [{text: '学校', value: 'school'}, {text: '其它', value: 'other'}];

  public valid = false;
  public category: any = this.categories[0];
  public title: string = '';
  public isActive: boolean = true;

  public async mounted() {
    await dispatchGetUnits(this.$store);
    this.reset();
  }

  public reset() {
    this.title = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUnitProfileCreate = {
        title: this.title,
      };
      if (this.category) {
        updatedProfile.category = this.category.value;
      }
      if (this.isActive) {
        updatedProfile.is_active = this.isActive;
      }

      await dispatchCreateUnit(this.$store, updatedProfile);
      this.$router.push('/main/admin/units/all');
    }
  }
}
